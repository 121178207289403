import { render, staticRenderFns } from "./drawing.vue?vue&type=template&id=086084d2&scoped=true&"
import script from "./drawing.vue?vue&type=script&lang=js&"
export * from "./drawing.vue?vue&type=script&lang=js&"
import style0 from "./drawing.vue?vue&type=style&index=0&id=086084d2&prod&lang=scss&scoped=scoped&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086084d2",
  null
  
)

export default component.exports