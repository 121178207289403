<template>
	<!-- 巡视信息列表 -->
  <div class="mod-config">
	  <el-card body-style="padding-bottom: 0;">
		<el-form ref="form" :model="form" inline label-width="80px" label-position="left">
			<el-row>
				<el-col :span="20">
					<el-form-item label="图纸名称">
						<el-select class="selItemInput" v-model="value" placeholder="请选择">
							<el-option
							  v-for="item in options"
							  :key="item.value"
							  :label="item.label"
							  :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
				</el-col>
				<el-col :span="4"  class="selBtnGroup">
					<el-button v-preventReClick class="primaryPlainBtn" type="primary" plain>重置</el-button>
					<el-button v-preventReClick class="primaryPlainBtn" type="primary">检索</el-button>
				</el-col>
			</el-row>
		</el-form>
	  </el-card>
	  <el-row style="margin-top: 20px;">
	  	<el-col :span="12">
	  		<el-button v-preventReClick class="defaultPlainBtn" plain icon="el-icon-circle-plus-outline">新增</el-button>
	  	</el-col>
	  	<el-col :span="12" style="text-align: right;">
	  		<el-pagination
	  		  background
	  		  layout="prev, pager, next"
	  		  :pager-count="5"
	  		  :page-size="20"
	  		  :total="200">
	  		</el-pagination>
	  	</el-col>
	  </el-row>
	  <div :style="siteContentViewHeight()">
		  <el-card
			style="margin-top: 20px; height: 100%;"
			body-style="height: 100%; padding: 0;">
			  <el-row style="height: 100%;">
				  <el-col :span="2" style="background-color: #e9f4fb; height: 100%;">
					<el-menu
					  :default-active="tabIndex"
					  class="tabMenu"
					  style="margin-top: 50px;">
						<el-menu-item index="0" style="text-align: center;">
							船体
						</el-menu-item>
						<el-menu-item index="1" style="text-align: center;">
							轮机
						</el-menu-item>
						<el-menu-item index="2" style="text-align: center;">
							舾装
						</el-menu-item>
					</el-menu>
				  </el-col>
				  <el-col :span="22" style="height: 100%;">
					  <el-table
						class="dataListTable"
						:data="tableData"
						stripe
						header-align="center"
						height="100%"
						style="width: 100%;"
						:row-class-name="tableRowClassName"
						header-cell-class-name="dataListTHeader"
						@selection-change="handleSelectionChange">
						<el-table-column
							type="selection"
							width="60"
							align="center">
						</el-table-column>
						<el-table-column
						  prop="text1"
						  label="员工编号"
						  width="180"
						  align="center">
						</el-table-column>
						<el-table-column
						  prop="text2"
						  label="专业"
						  width="180"
						  align="center">
						</el-table-column>
						<el-table-column
						  prop="text3"
						  label="类型"
						  width="180"
						  align="center">
						</el-table-column>
						<el-table-column
								  prop="text5"
								  label="图纸编号"
								  width="150"
								  align="center">
						</el-table-column>
						<el-table-column
								  prop="text5"
								  label="图纸名称"
								  align="center">
						</el-table-column>
						<el-table-column
								  prop="text5"
								  label="图纸版本"
								  width="150"
								  align="center">
						</el-table-column>
						<el-table-column
						  prop="text4"
						  label="巡视时间 "
						  width="150"
						  align="center">
						</el-table-column>
						<el-table-column
						  label="操作"
						  width="200"
						  align="center">
						  <template>
							  <el-button v-preventReClick type="danger" size="small">修改</el-button>
							  <el-button v-preventReClick type="primary" size="small">查看</el-button>
						  </template>
						</el-table-column>
					  </el-table>
				  </el-col>
			  </el-row>
		  </el-card>
	  </div>
<!--	  <add-or-update v-if="addOrUpdateVisible" ref="addOrUpdate" @refreshDataList="getDataList()"></add-or-update>-->
  </div>
</template>
<script>
// import AddOrUpdate from './patrolList-add-or-update'
export default {
  data() {
    return {
      tabIndex: "0",
	  addOrUpdateVisible: false,
	  form:{},
	  value:"",
	  value1:"",
	  checked:"",
	  options: [
		  {
			  value:"1",
			  label:"2"
		  }
	  ],
	  tableData:[
		  {
			id: "1",
			text1: "结构",
			text2: "现场1",
			text3: "张三",
			text4: "2021-06-28 ",
			text5: "CMI-123-1 ",
			text6: "巡视内容巡视内容巡视内容巡视内容",
		  },
		  {
			id: "2",
			text1: "结构",
			text2: "现场1",
			text3: "张三",
			text4: "2021-06-28 ",
			text5: "",
			text6: "巡视内容巡视内容巡视内容巡视内容",
		  },
		  {
			id: "3",
			text1: "结构",
			text2: "现场1",
			text3: "张三",
			text4: "2021-06-28 ",
			text5: "",
			text6: "",
		  }
	  ],
	  tableSelVal:[]
    };
  },
  components: {
	// AddOrUpdate
  },
  activated() {

  },
  methods: {
	siteContentViewHeight () {
	  var height = this.$store.state.common.documentClientHeight - 260;
	  return { height: height + 'px' }
	},
	handleSelectionChange(val) {
		this.tableSelVal = val;
	},
	tableRowClassName({ row, rowIndex }) {
	  let color = ''
	  for(let item of this.tableSelVal){
		if(item.id === row.id)color = 'table-SelectedRow-bgcolor'
	  }
	  return color
	},
  }
};
</script>
<style lang="scss" scoped="scoped">
	.selItemText{
		font-size: 14px;
		color: #1c1c1c;
		display: inline-block;
		width: 80px;
	}
</style>
